import { createSharedComposable, useScriptTag } from '@vueuse/core';

interface IntercomUser {
  name?: string;
  email?: string;
  'User ID'?: string;
  Role?: string;
  Login?: string;
}

function _useIntercom() {
  const isBooted = ref(false);

  /** Visible */
  const visible = ref(false);

  function addOpenHideListeners() {
    window.Intercom('onShow', () => (visible.value = true));
    window.Intercom('onHide', () => (visible.value = false));
  }

  /** Unread Count */
  const unreadCount = ref(0);
  function addOnUnreadCountChangeListener() {
    window.Intercom('onUnreadCountChange', (newCount: number) => (unreadCount.value = newCount));
  }

  /** Load */
  const intercomAppId = useRuntimeConfig().public.INTERCOM_APP_ID;

  const defaultOptions = {
    app_id: intercomAppId,
    Portal: location.origin,
    custom_launcher_selector: '#intercom_support',
    hide_default_launcher: true,
    horizontal_padding: 24,
  };

  window.intercomSettings = defaultOptions;

  if (process.env.NODE_ENV !== 'development') {
    useScriptTag(
      `https://widget.intercom.io/widget/${intercomAppId}`,
      () => {
        addOpenHideListeners();
        addOnUnreadCountChangeListener();
      },
      { defer: true },
    );
  }

  /** use */
  function callIntercom (...args: unknown[]) {
    if (!window.Intercom) return;
    return window.Intercom(...args);
  }

  function update(options: IntercomUser = {}) {
    callIntercom('update', options);
  }

  function boot(options: IntercomUser = {}) {
    if (isBooted.value) return update(options);
    callIntercom('boot', Object.assign({}, defaultOptions, options));
    isBooted.value = true;
  }

  return {
    visible,
    isBooted,
    unreadCount,
    boot,
    update,
  };
}

export const useIntercom = createSharedComposable(_useIntercom);
