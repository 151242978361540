<script lang="ts">
import { useClipboard } from '@vueuse/core';

import { AR, useAccess } from '~/assets/mixins/accessMixin';
import { useApi } from '~/plugins/api';
import { ServiceName } from '~/components/services';
import { usePackageJson } from '~/assets/utils/usePackageJson';

interface Version {
    name: string;
    version?: string;
    error?: string;
    url?: string;
    changelog?: boolean;
}

export default defineComponent({
    setup() {
        const runtimeConfig = useRuntimeConfig().public;

        const packageJson = usePackageJson();
        const spaVersion: Version = { name: ServiceName.Spa, version: usePackageJson().version };
        const uiKitVersion: Version = { name: ServiceName.Uikit, url: '/ui-kit.md', version: packageJson.dependencies['@frontend/ui-kit'].replace('^', ''), changelog: true };
        const api = useApi();

        const apisInfo = ref([spaVersion, uiKitVersion]);

        const { canByRole } = useAccess();
        const { loggedIn } = useUserStore();

        function getUrl(name) {
            switch (name) {
                case ServiceName.Iris: return runtimeConfig.API_URL + '/docs';
                case ServiceName.Muse: return runtimeConfig.API_URL + '/md/docs';
                case ServiceName.Clio: return canByRole([AR.Admin, AR.Support]) ? runtimeConfig.CLIO_URL + '/docs' : '';
                default: return '';
            }
        }

        function hasChangelog(name) {
            switch (name) {
                case ServiceName.Iris: return false;
                case ServiceName.Muse: return true;
                case ServiceName.Clio: return true;
                default: return false;
            }
        }

        onActivated(loadVersions);

        loadVersions();
        function loadVersions() {
            if (!loggedIn) return;
            api.versions().then((answer) => {
                if (!answer || !Array.isArray(answer)) return;
                apisInfo.value = [
                    spaVersion,
                    uiKitVersion,
                    ...answer.map(({ service_name, version, error }) => ({
                        name: service_name,
                        url: getUrl(service_name),
                        changelog: hasChangelog(service_name),
                        version,
                        error,
                    })),
                ];
            });
        }

        const copyText = computed(() => {
            return apisInfo.value.filter(info => !info.error).map(info => `${info.name}: ${versionToString(info.version)}`).join('\n');
        });

        const { addSuccess } = useNotificationsStore();
        const { copy } = useClipboard();

        function copyVersion(version) {
            copy(versionToString(version));
            addSuccess('Version copied');
        }

        function versionToString(v) {
            return `v${v}`;
        }

        function hasChangelogLink(info) {
            if ([ServiceName.Clio, ServiceName.Muse].includes(info.name)) {
                return info.changelog && canByRole([AR.Admin, AR.Support, AR.Auditor, AR.DataAdmin, AR.SeniorEditor, AR.Supervisor]);
            }

            return !!info.changelog;
        }

        return {
            copyText,
            loggedIn,
            apisInfo,
            copyVersion,
            UiIconName,
            versionToString,
            hasChangelogLink,
        };
    },
});
</script>

<template lang="pug">
UiDropdown(
    :hover="true"
    :openDelay="1000"
    :closeDelay="500"
    :disabled="!loggedIn"
)
    template(v-slot:opener)
        slot
    template(v-slot="{close}")
        UiCopyContainer(
            :text="copyText"
            @copy="close"
        )
            template(v-slot:header) Versions
            .services
                .service(v-for="info of apisInfo" :key="info.name")
                    UiLink.service_name(:href="info.url" :newTab="true") {{ info.name }}
                    template(v-if="!info.error")
                        .version-container
                            UiLink.service_version(:href="hasChangelogLink(info) && `/versions?service=${info.name}` || ''") {{ versionToString(info.version) }}
                            UiIconButton(:name="UiIconName.FileAndFolder_DocumentCopy" :size="14" @click="copyVersion(info.version)")
                    template(v-else)
                        UiTooltip.service_error(:text="info.error") Error
</template>

<style lang="scss" scoped>
@import "@frontend/ui-kit/src/runtime/styles/spacing";
@import "@frontend/ui-kit/src/runtime/styles/colors";

.services {
    @include column_with_space();
    width: 192px;
}

.service {
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
}

.service_name {
    color: $ui-color-text-secondary;
}

.service_version {
    color: $ui-color-text-default;
}

.service_error {
    color: $ui-color-text-error;
}

.version-container {
    @include elements_with_space();
}
</style>
