<script lang="ts" setup>
import { useIntercom } from './useIntercom';

const { user } = storeToRefs(useUserStore());
const { visible, unreadCount, boot } = useIntercom();

watch(user, () => {
  if (!user.value) return;
  const { username, email, id, role, login } = user.value;
  boot({
    name: username,
    email,
    'User ID': id,
    Role: role,
    Login: login,
  });
}, { immediate: true });

const locale = 'nav.message';
</script>

<template lang="pug">
  UiTooltip(:text="locale" :disabled="visible")
    UiAvatar.intercom#intercom_support(
      :active="visible"
      :status="false"
      :badge="unreadCount"
      :data-qa="locale"
    )
      UiIconTemplate.icon(:name="UiIconName.Chat_Fill" :size="20")
</template>

<style lang="scss">
@import "@frontend/ui-kit/src/runtime/styles/colors";

.intercom-messenger-frame {
  top: 52px !important;
  z-index: 1000 !important;
}

.intercom {
  .ui-avatar-image {
    background: $ui-color-tag-bg-aquamarine;
  }

  &:hover .ui-avatar-image {
    background: $ui-color-button-bg-primary-main-hover !important;
  }
}
</style>

<style lang="scss" scoped>
@import "@frontend/ui-kit/src/runtime/styles/colors";

.icon {
  color: $ui-color-icon-primary;
}

.intercom {
  &:hover {
    .icon {
      color: $ui-color-button-icon-primary-default;
    }
  }
}
</style>
