<script setup lang="ts">
import { allLocales } from '~/components/header/locales';

const { locale } = useI18n();

function setLocale(code, close) {
  locale.value = code;
  close();
}


const activeLocale = computed(() => {
  const l = allLocales.find(i => i.code === locale.value);
  localStorage.setItem('userLocale', l.code);
  return l;
});

const listOfLocales = computed(() => allLocales.filter(({ code }) => code !== locale.value && code !== 'dev').sort((a, b) => a.code.localeCompare(b.code)));

const textLocale = 'nav.language';
</script>

<template lang="pug">
UiDropdown(:border="false")
    template(v-slot:opener="{isOpen}")
        UiTooltip(:text="textLocale" :disabled="isOpen")
            UiAvatar(:status="false" :data-qa="textLocale")
                .locale-text {{ activeLocale.abbreviation }}
    template(v-slot="{close}")
        UiList
            UiListItem(
                v-for="{code, label} of listOfLocales"
                :key="code"
                :data-qa="code"
                @click="setLocale(code, close)"
            ) {{ label || code }}
</template>

<style lang="scss" scoped>
@import "@frontend/ui-kit/src/runtime/styles/colors";

.locale-text {
    display: flex;
    justify-content: center;
    font-size: 13px;
    line-height: 20px;
    color: $ui-color-icon-default;
    font-weight: 700;
    width: 100%;
}
</style>
