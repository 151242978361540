<script setup lang="ts">
const { logout } = useUserStore();

const menu = [
  { onClick: () => {}, href: '/old/profile', locale: 'nav.profile' },
  { onClick: logout, locale: 'nav.logout' },
];

const locale = 'nav.profile';
</script>

<template lang="pug">
UiDropdown
    template(v-slot:opener="{isOpen}")
        UiTooltip(:text="locale" :disabled="isOpen")
            UiAvatar(:data-qa="locale" :status="false")
                UiIconTemplate(:name="UiIconName.Users_Profile" :size="20")
    template(v-slot)
        UiList
            component(
                v-for="item of menu"
                :is="item.href ? UiListMenuItem : UiListItem"
                :key="item.locale"
                :href="item.href"
                :data-qa="item.locale"
                @click="item.onClick"
            ) {{ $t(item.locale) }}
</template>
