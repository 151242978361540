<script setup lang="ts">
import { AN, AT, AR, useAccess } from '~/assets/mixins/accessMixin';
import MobileMenu from '~/components/header/MobileMenu.vue';

import ServicesVersions from './ServicesVersions.vue';
import Support from './SupportChat.vue';
import Reference from './Reference.vue';
import LocaleSelector from './LocaleSelector.vue';
import ReleaseUpdates from './ReleaseUpdates.vue';
import HeaderProfile from './HeaderProfile.vue';
import RecentPages from './RecentPages.vue';

const LINE = { visible: true };
const { can, canByRole } = useAccess();
const { loggedIn } = storeToRefs(useUserStore());
const settingsStore = useSettingsStore();

const devStore = useDevStore();

const { isMobile } = useAdaptive();

function filterVisible(elements) {
  return elements.filter(({ visible }) => visible);
}

function filterAndAddLine(elements) {
  return elements.map(filterVisible).filter(g => g.length).flatMap((g, index) => index ? [LINE, ...g] : g);
}

const analyticsItems = computed(() => filterVisible([
  { locale: 'nav.dashboard', to: '/dashboards', visible: can(AN.Analytics, AT.Menu) },
  { locale: 'nav.report_results', to: '/reports', visible: can(AN.DailyReport, AT.Menu) },
  { locale: 'nav.billing', to: '/billing', visible: can(AN.Billing, AT.Menu) },
]));

const menuItems = computed(() => filterAndAddLine([
  [
    { locale: 'nav.products', href: '/old/product', visible: can(AN.Product, AT.Menu) },
    { locale: 'nav.prod_cats', href: '/old/product-category', visible: can(AN.ProductCategory, AT.Menu) },
    {
      locale: 'nav.equipment_and_posm',
      to: '/equipment-and-posm',
      visible: canByRole([AR.Admin, AR.SeniorEditor, AR.Supervisor, AR.Support]),
    },
  ], [
    { locale: 'nav.stores', to: '/stores', visible: can(AN.Store, AT.Menu) },
    { locale: 'nav.routes', to: '/routes', visible: settingsStore.showRoutes && can(AN.Store, AT.Menu) },
    { locale: 'nav.segments', to: '/segments', visible: can(AN.Store, AT.Menu) },
    { locale: 'nav.retailers', to: '/retailers', visible: can(AN.Retailer, AT.Menu) },
    { locale: 'countries.countries', to: '/countries', visible: can(AN.Country, AT.Menu) },
    { locale: 'nav.regions', to: '/regions', visible: can(AN.Region, AT.Menu) },
    { locale: 'nav.cities', to: '/cities', visible: can(AN.City, AT.Menu) },
  ], [
    { locale: 'nav.areas', to: '/areas', visible: can(AN.Areas, AT.Menu) },
    { locale: 'nav.teams', href: '/old/team', visible: can(AN.Team, AT.Menu) },
  ], [
    { locale: 'nav.metrics', to: '/metrics', visible: can(AN.Metrics, AT.Menu) },
    { locale: 'nav.planogramm', to: '/planogramm', visible: settingsStore.showPlanogramm && can(AN.Plan, AT.Menu) },
    { locale: 'nav.planned_metrics', to: '/planned-metrics', visible: can(AN.Plan, AT.Menu) },
    { locale: 'nav.planned_width', href: '/old/kpi/assortment', visible: can(AN.Plan, AT.Menu) },
    { locale: 'nav.planned_photos', href: '/old/kpi/photo', visible: can(AN.Plan, AT.Menu) },
    { locale: 'nav.planned_shelf', href: '/old/kpi/sos', visible: can(AN.Plan, AT.Menu) },
    { locale: 'nav.assortment_matrices', to: '/plans/assortment', visible: can(AN.Plan, AT.Menu) },
    { locale: 'nav.assortment_type', to: '/plans/assortment-type', visible: can(AN.PlanType, AT.Menu) },
    { locale: 'nav.price-standards', to: '/price-standards', visible: can(AN.PriceStandards, AT.Menu) },
  ], [
    { locale: 'nav.task_templates', to: '/tasks', visible: can(AN.Tasks, AT.Menu) },
    { locale: 'nav.scene_groups', to: '/scene-groups', visible: can(AN.SceneType, AT.Menu) },
    { locale: 'sales_locations.sales_locations', to: '/sales-locations', visible: can(AN.SceneType, AT.Menu) },
    { locale: 'nav.shelf_share_formulas', href: '/old/share-exp', visible: can(AN.ShareExp, AT.Menu) },
  ], [
    { locale: 'nav.periods', to: '/periods', visible: can(AN.Periods, AT.Menu) },
  ],
  [
    { locale: 'nav.visit_recalc', to: '/mass-recount', visible: devStore.dev.massRecount && can(AN.Visit, AT.Menu) },
  ],
  [
    {
      locale: 'nav.imports',
      to: '/imports',
      visible: canByRole([AR.Admin, AR.DataAdmin, AR.SeniorEditor, AR.Supervisor, AR.Support]),
    },
  ],
]));

const settingsItems = computed(() => filterAndAddLine([
  [
    devStore.dev.spa ? { locale: 'SPA DEV', to: '/spa', visible: true } : {},
  ], [
    { locale: 'nav.portal_settings', href: '/old/settings', visible: can(AN.Settings, AT.Menu) },
    { locale: 'nav.mobile_settings', to: '/settings/mobile', visible: can(AN.AppSettings, AT.Menu) },
    { locale: 'nav.integrations', href: '/old/package', visible: canByRole(AR.Admin) },
  ], [
    { locale: 'nav.lots', href: '/old/lot', visible: can(AN.Lot, AT.Menu) },
  ], [
    { locale: 'nav.users', to: '/users', visible: can(AN.User, AT.Menu) },
    { locale: 'nav.roles', href: '/old/rbac', visible: can(AN.Rbac, AT.Menu) },
  ], [
    { locale: 'nav.related_lists', href: '/old/unified-list', visible: can(AN.UnifiedList, AT.Menu) },
    { locale: 'nav.share_files', href: '/old/download', visible: can(AN.Download, AT.Menu) },
  ],
]));

const pages = computed(() => filterVisible([
  { locale: 'nav.sfa_tasks', to: '/sfa-tasks', visible: settingsStore.showSfaTasks && can(AN.Tasks, AT.Menu) },
  { locale: 'nav.visits', to: '/visits', visible: can(AN.Visit, AT.Menu) },
  { locale: 'nav.photos', to: '/photos', visible: can(AN.Visit, AT.Menu) && !canByRole(AR.Merch) },
  { locale: 'nav.recent', visible: true, component: RecentPages },
  { locale: 'nav.analytics', visible: analyticsItems.value.length, items: analyticsItems.value },
  { locale: 'nav.menu', visible: menuItems.value.length, items: menuItems.value },
  { locale: 'nav.settings', visible: settingsItems.value.length, items: settingsItems.value },
]));
</script>

<template lang="pug">
UiHeader.header
    MobileMenu(v-if="loggedIn && isMobile" :pages="pages")
    ServicesVersions
        UiLink(to="/" data-qa="logo")
            .logo
                UiLogo(
                    :width="32"
                    :isMark="true"
                )
    template(v-slot:left)
      template(v-if="loggedIn && !isMobile")
        template(v-for="{to, locale, items, component} of pages")
          UiLinkAsButton.header_menu-button.link-item(
            v-if="to"
            :key="locale"
            :to="to"
            :locale="locale"
            :link="true"
            :theme="UiStyles.UiTheme.Secondary"
          )
          UiDropdown(v-else placement="bottom-start" :key="locale + '_dropdown'" :border="false")
            template(v-slot:opener="{isOpen}")
              UiButton.header_menu-button(:theme="UiStyles.UiTheme.Secondary" :class="[isOpen ? '_open' : '']" :locale="locale" :link="true")
                template(v-slot:rightIcon)
                  UiIconTemplate.icon(:size="16" :name="UiIconName.ChevronDown")
            template(v-slot="{close}")
              component(v-if="component" :is="component" @click="close")
              UiList.menu-list(v-else)
                template(v-for="(item, index) of items")
                  UiListMenuItem.link-item(
                    v-if="item.locale"
                    :locale="item.locale"
                    :key="item.locale"
                    :to="item.to"
                    :href="item.href || item.to"
                    :data-qa="item.locale"
                    @click="close"
                  )
                  UiListItemLine(:key="index" v-else)
    template(v-slot:right)
        ReleaseUpdates(v-if="!isMobile && loggedIn")
        Reference(v-if="!isMobile && loggedIn")
        HeaderProfile(v-if="loggedIn")
        LocaleSelector
        Support
</template>

<style lang="scss" scoped>
@import "@frontend/ui-kit/src/runtime/styles/colors";
@import "@frontend/ui-kit/src/runtime/styles/borderRadius";

.header {
    .header_menu-button {
        background: none;
        font-weight: 500;
        color: $ui-color-text-secondary;
        font-size: 14px;
        outline: none;
        white-space: nowrap;

        &:hover {
            background: $ui-color-selector-bg-hover;
            color: $ui-color-text-default;
        }

        &._open {
            background: $ui-color-selector-bg-hover;
            color: $ui-color-text-default;

            .icon {
                transform: rotate(180deg);
            }
        }

        &.router-link-active {
            position: relative;

            &::after {
                content: "";
                background: $ui-color-text-default;
                height: 6px;
                border-radius: $ui-kit-border-radius-2x;
                width: 100%;
                position: absolute;
                bottom: -12px;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }
    }
}

.menu-list {
    max-height: 512px;
    overflow: auto;
}

.link-item {
    :deep(.link) {
        color: $ui-color-text-secondary;
    }
}

.logo {
  height: 32px;
  width: 32px;
}

:deep(.router-link-active) {
    color: $ui-color-text-default !important;
}
</style>
