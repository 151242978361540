export function useReleaseUpdates() {
    const { locale } = useI18n();
    const link = computed(() => {
        return `/docs/${locale.value === 'ru' ? 'ru' : 'en'}/updates/`;
    });

    return {
        link,
    };
}

export function useHelp() {
    const { locale } = useI18n();
    const link = computed(() => {
        return `/docs/${locale.value}/`;
    });

    return {
        link,
    };
}
